<template>
    <div>
      <article>
        <section>
          <div class="region_dialogs">
            <el-dialog
              :title="`Update Region in ${country ? country : '...'}`"
              :visible.sync="isEditRegionModalVisible"
              :destroy-on-close="true"
              :before-close="handleClose"
              style="text-align: left"
            >
              <div v-loading="submitting">
                <el-form
                  :model="regionForm"
                  :rules="rules"
                  label-position="top"
                  ref="regionForm"
                  class="demo-regionForm2"
                >
                  <div class="row">
                    <div class="col-md-12">
                      <el-form-item
                        label="Region Name"
                        prop="region_name"
                      >
                        <el-input v-model="regionForm.region_name"></el-input>
                      </el-form-item>
                    </div>
                  </div>
  
                  <hr />
                  <el-form-item class="text-center">
                    <el-button
                      style="background-color: #083628; color: white;"
                      @click="editRegion('regionForm')"
                    > Save Region Changes in {{country ? country : '...'}}</el-button>
                  </el-form-item>
                </el-form>
  
                <div class="text-center">
                  <div class="text-center">
                    <small>© 2022 Everythingirly, All rights reserved.</small>
                  </div>
                  <div class="text-center">
                    <!--v-model="regionForm.Course" <small>Design by <a href="https://ovalspace.co" style="color: black;"><strong>Ovalspace</strong></a></small> -->
                  </div>
                </div>
              </div>
            </el-dialog>
          </div>
        </section>
      </article>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        loading: false,
        isLoading: false,
  
        isEditRegionModalVisible: false,
        loadingError: false,
        submitting: false,
        regionForm: {
          region_name: "",
        },
  
        rules: {
          region_name: [
            {
              required: true,
              message: "Region Name is required",
              trigger: "blur",
            },
          ],
        },
      };
    },
  
    props: {
      showRegionEditDialog: {
        required: true,
        type: Boolean,
      },
      regionData: {
        required: true,
        type: Object,
      },
      country:{
        type: String,
        required: true
      } 
    },
  
    watch: {
      showRegionEditDialog() {
        if (this.showRegionEditDialog === true) {
          this.isEditRegionModalVisible = true;
          this.regionForm.region_name =
            this.regionData.region_name;
        } else {
          this.isEditRegionModalVisible = false;
        }
      },
    },
  
    methods: {
      handleClose(done) {
        done();
        this.$emit("closeEditRegionDialog");
      },
  
      async editRegion(formName) {
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            try {
              this.submitting = true;
              let response = await this.$http.patch(
                `regions/${this.regionData.region_id}`,
                { region_name: this.regionForm.region_name }
              );
              if (
                response.data.success &&
                response.data.message == "REGION_UPDATED_SUCCESSFULLY"
              ) {
                this.$refs[formName].resetFields();
                this.$emit("re-fresh");
                this.$notify({
                  title: "Success",
                  message: "Region updated successfully",
                  type: "success",
                });
                this.$emit("closeEditRegionDialog");
              } else {
                throw "UNEXPECTED_RESPONSE";
              }
            } catch (error) {
              if (error.message == "Network Error") {
                return this.$notify({
                  title: "Connection failed",
                  message: "A network error occurred, please try again.",
                  type: "error",
                });
              }
              this.$notify({
                title: "Unable to update Region",
                message: "An unexpected Error occurred, please try again",
                type: "error",
              });
            } finally {
              this.submitting = false;
            }
          } else {
            return false;
          }
        });
      },
    },
  };
  </script>
  
  <style>
  </style>