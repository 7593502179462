<template>
    <div>
                    <el-button
                      type="success" round
        @click="showRegionDialog"
                    >Add Region in {{country ? country : '...'}}</el-button>
      <article>
        <section>
          <div class="region_dialogs">
            <el-dialog
              :title="`Add Region in ${country ? country : '...'}`"
              :visible.sync="addRegionModal"
              :destroy-on-close="true"
              :before-close="handleClose"
              style="text-align: left"
            >
              <div v-loading="submitting">
                <el-form
                  :model="regionForm"
                  :rules="rules"
                  label-position="top"
                  ref="regionForm"
                  class="demo-countryForm2"
                >
                  <div class="row">
                    <div class="col-md-6">
                      <el-form-item
                        label="Region Name"
                        prop="region_name"
                      >
                        <el-input v-model="regionForm.region_name"></el-input>
                      </el-form-item>
                    </div>
                  </div>
  
                  <hr />
                  <el-form-item class="text-center">
                    <el-button
                      type="success" round
                      @click="addRegion('regionForm')"
                    >Add Region in {{country ? country : '...'}}</el-button>
                  </el-form-item>
                </el-form>
  
                <div class="text-center">
                  <div class="text-center">
                    <small>© 2022 Everythingirly, All rights reserved.</small>
                  </div>
                  <div class="text-center">
                    <!--v-model="regionForm.Course" <small>Design by <a href="https://ovalspace.co" style="color: black;"><strong>Ovalspace</strong></a></small> -->
                  </div>
                </div>
              </div>
            </el-dialog>
          </div>
        </section>
      </article>
    </div>
  </template>
  <script>
  export default {
    data() {
      return {
        addRegionModal: false,
        submitting: false,
        regionForm: {
          region_name: "",
        },
        rules: {
          region_name: [
            {
              required: true,
              message: "Region Name is required",
              trigger: "blur",
            },
          ],
        },
      };
    },

    props: {
      country:{
        type: String,
        required: true
      } 
    },

    methods: {
      handleClose(done) {
        done();
      },
      showRegionDialog() {
        this.addRegionModal = true;
        this.regionForm.region_name = "";
      },
      async addRegion(formName) {
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            try {
              this.submitting = true;
              let response = await this.$http.post(`regions`, {
                region_name: this.regionForm.region_name,
                country_id: this.$route.params.countryId,
                created_by: this.$store.state.userId,
              });
              if (
                response.data.success &&
                response.data.message == "REGION_CREATED_SUCCESSFULLY"
              ) {
                this.$refs[formName].resetFields();
                this.$emit("re-fresh");
                this.$notify({
                  title: "Success",
                  message: "Region added successfully",
                  type: "success",
                });
                this.addRegionModal = false;
              } else {
                throw "UNEXPECTED_RESPONSE";
              }
            } catch (error) {
              if (error.message == "Network Error") {
                return this.$notify({
                  title: "Connection failed",
                  message: "A network error occurred, please try again.",
                  type: "error",
                });
              }
              this.$notify({
                title: "Unable to Add Region",
                message: "An unexpected Error occurred, please try again",
                type: "error",
              });
            } finally {
              this.submitting = false;
            }
          } else {
            return false;
          }
        });
      },
    },
  };
  </script>